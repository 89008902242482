<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
//import "./html&css/vendor/popper.js/dist/umd/popper.min.js";
import "./html&css/vendor/bootstrap/dist/js/bootstrap.min.js";
////import "./html&css/vendor/onscreen/dist/on-screen.umd.min.js";
//import "./html&css/vendor/nouislider/distribute/nouislider.min.js";
//import "./html&css/vendor/jarallax/dist/jarallax.min.js";
//import "./html&css/vendor/smooth-scroll/dist/smooth-scroll.polyfills.min.js";
//import "./html&css/vendor/countup.js/dist/countUp.umd.js";
//import "./html&css/vendor/notyf/notyf.min.js";
// import "./html&css/vendor/chartist/dist/chartist.min.js";
// import "./html&css/vendor/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.min.js";
//import "./html&css/vendor/vanillajs-datepicker/dist/js/datepicker.min.js";
//import "./html&css/vendor/simplebar/dist/simplebar.min.js";
//import "./html&css/assets/js/volt";

export default {
  name: "app"
};
</script>

<style>
@import "./html&css/vendor/@fortawesome/fontawesome-free/css/all.min.css";
@import "./html&css/vendor/notyf/notyf.min.css";
@import "./html&css/css/volt.css";
</style>