import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from './components/auth/Login.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/login', component: Login, meta: {
    title: 'Login',
      metaTags: [
        {
          name: 'Login',
          content: 'Login Portal.'
        },
        {
          property: 'og:description',
          content: 'Login Portal.'
        }
      ]
    }
  },
];

export default new VueRouter({ mode: 'history', routes });
