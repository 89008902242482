import Vue from 'vue';
import Vuex from 'vuex';
import axios from './axios-auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    login(authData) {
      axios
        .post('/login', {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log(res);
         
        })
        .catch(error => console.log(error));
    }
  }
});
